import clientSay1 from "images/clientSay1.jpg";
import clientSay4 from "images/clientSay4.jpg";
import clientSay5 from "images/clientSay5.jpg";

export const globalJson = {
  // apiUtil auth header key
  authToken: "Bearer 166|6v07b24t9XrBqMyUzMnZJxIhl76qXddhIoWl25Ix",
  companyId: "bbf0676a-8536-4226-9ee9-b0148379c7a2",
  stripePublishableKey:
    "pk_live_51PBpF1E1dI41kKbS6Z6SOCQK73iCBeJIOpkc2eUVwANT0cV4UCsmaDjrMiEPDSPY96FYIP2syw0Lkt8t6uDM7LXv00hQdzPRHa",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  adminEmail: "carrentalutah@gmail.com",
  adminPhone: "1 (319) 536-5662",
  residenceStateCode: "UT",

  //email confirmation admin token
  adminEmailToken:
    "baRXATgwcOMno6cRP5M8L7ZGdBp8V9ipgIGFBBLryYPQjAw09zp1DyWr9mZ5LZS662kfzv3R6ectbMr0RUMmjHttVxEBsmrgmWfLP47ZdydJZPVUlEThO5SPuNYpTN4a",
  websiteLink: "https://utah-carrental-dev.1now.app",
  sender: "aarrental-uta",
  ccEmail: "jonathan@quantumqubemarketing.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_p2onnze",
  util_templateId: "template_qh2vavo",
  util_userId: "36Zns1smFHX2WHGYO",
  util_privateKey: "xdtrEmhZJoZqVtJIfEJRN",

  // apiUtil getBonzahToken
  client_secret_dev:
    "yH7DBMiW5lVVbpi+a32WuF3IFLOj2HoDsd5scfeYVoUsPA0eteUivZtijGd6EPtBrs0uuix3MtIL8yOHiBgsdDe2HHqQcsYQW1CjI1W8l8Xl/2t8s9MkYWPNlykkdsVHXy+j/w==",
  client_id_dev:
    "f95466e1-f2b5-427b-a1ef-f5c2b21ced12-20240612132608-vendor@bonzah.com",
  client_secret_prod:
    "+fmMGda5vcWi/kqU/d5m4cgPAepw5NrimJlWTK+SMFj4LtS+mJnDO5QVL6EupfLce1x0Gdsqd9xCa02OHZhfRsvfaBpfRljDETS++6K7ng4lqSZnePuQ36RHDhIM3V6uLBkC7A==",
  client_id_prod:
    "9646fc8c-03f6-405f-819c-e1030db00623-20240612132079-vendor@bonzah.com",

  agreement_key:
    "https://firebasestorage.googleapis.com/v0/b/carrentalutah-f1752.appspot.com/o/agreements%2F",
  bookingId_key: ".pdf?alt=media&token=68c6c059-fdb4-4f33-b694-623b71c506a1",

  //   bg_config
  firebaseConfig: {
    apiKey: "AIzaSyCLyJ-BlqnL9LttyIDAv3SjuDky_V94J7U",
    authDomain: "carrentalutah-f1752.firebaseapp.com",
    projectId: "carrentalutah-f1752",
    storageBucket: "carrentalutah-f1752.appspot.com",
    messagingSenderId: "1099304212104",
    appId: "1:1099304212104:web:8ad71e9aec3b4b6be9713d",
    measurementId: "G-04Q6LHS8FL",
  },

  about_us_para:
    "ERM Car Rental offers an extensive range of high-quality, well-maintained vehicles tailored to fit any traveller’s needs, whether for business or leisure. Our commitment to exceptional customer service and competitive pricing ensures a seamless rental experience from booking to return. We prioritize convenience and flexibility, offering various pickup and drop-off options to accommodate your schedule. As a leader in the industry, ERM Car Rental strives to meet and exceed customer expectations with each rental, providing a reliable and enjoyable journey every time. ",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Cale Sieverts",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Honest, delivers a great experience, and communicates clearly! I would do business with them over any other rental car company in Utah County.",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Jay Grijalva",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "They were super helpful with everything we needed and made it super easy to work with him. Highly recommend renting a car from here if you’re in need in the Utah area! ",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Heathelyn Kolditz",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Extremely helpful! Would absolutely rent a car here again! Jonathon was absolutely amazing! Extremely helpful, personable and just great to work with!! His response time was phenomenal, he anticipated difficulties and helped me problem solve, and he was extremely generous with his time and help. I would absolutely rent another vehicle from him!",
      img: clientSay5,
    },
  ],

  //social links
  facebook: "https://www.facebook.com/",
  instagram: "https://www.instagram.com/",

  //contact
  address: "Provo, UT",
  phone: "(801) 857-6966",
  email: "contactus@carrental-provo.com",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46614487.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
