import { useApp } from "data/app-provider";

function TermsAndConditions() {
  const { companyData } = useApp();

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: companyData?.company?.meta?.terms ?? "",
      }}
    />
  );
}

export default TermsAndConditions;
