import React, { FC } from "react";
import { LocationType, TaxonomyType } from "data/types";
import NcImage from "shared/NcImage/NcImage";
import { useApp } from "data/app-provider";
import locationAvatar from "images/locationavatar.png"
export interface CardCategoryBox1Props {
  className?: string;
  location: LocationType;
}

const CardCategoryBox1: FC<CardCategoryBox1Props> = ({
  className = "",
  location,
}) => {
  const { name } = location;
  const { companyData, setPickupLocation, setSearchedPickupLocation } =
    useApp();

  const handleClick = () => {
    const deliveryLocations = companyData?.company?.delivery_locations || [];
    const matchingLocation = deliveryLocations.find(
      (location: { name: string }) => location.name === name
    );

    if (matchingLocation) {
      setPickupLocation(matchingLocation);
      setSearchedPickupLocation(matchingLocation.name);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
      data-nc-id="CardCategoryBox1"
      onClick={handleClick}
    >
      

      <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
        <NcImage src={locationAvatar} containerClassName="absolute inset-0" />
      </div> 
      <div className="ml-4 flex-grow overflow-hidden">
        <h2 className="text-base font-medium">
          <span className="text-base font-medium leading-6 text-left text-[#111827]">
            {name}
          </span>
        </h2>
        <span
          className={`block mt-2 text-sm text-[#6B7280] font-medium`}
        >
          19 minutes drive
        </span>
      </div>
    </div>
  );
};

export default CardCategoryBox1;
