// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  DocumentData,
} from "firebase/firestore";
import { FleetwireCustomer } from "models/FleetwireCustomer";
import UserDocModel from "models/UserModel";
import { createFleetwireCustomer } from "utils/apiUtil";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import toast from "react-hot-toast";
import { globalJson } from "global/global_json";

const firebaseConfig = { ...globalJson?.firebaseConfig };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);

const signInWithGoogle = async (
  navigate: any,
  setUser: any,
  fleetwireToken: string,
  redirectUrl: string | null
) => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      const tokenDocRef = await getDoc(
        doc(firestore, "Tokens", "FleetwireAdmin")
      );

      const userDocRef = doc(firestore, "users", result.user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        const fleetwireCustomer = (await createFleetwireCustomer(
          result.user.displayName ?? result.user.email!,
          result.user.email!,
          tokenDocRef.data()?.key
        )) as FleetwireCustomer;

        saveUserDetails(result.user.uid, {
          uid: result.user.uid,
          email: result.user.email,
          phone: null,
          displayName: result.user.displayName ?? result.user.email,
          renterId: fleetwireCustomer.renter.r_id,
          photoURL: result.user.photoURL,
        });
        setUser({
          uid: result.user.uid,
          email: result.user.email,
          phone: null,
          displayName: result.user.displayName ?? result.user.email,
          renterId: fleetwireCustomer.renter.r_id,
          photoURL: result.user.photoURL,
        });
      } else {
        const userDetails = userDoc.data() as UserDocModel;
        setUser(userDetails);
      }
      toast.success("Signin Successful");
      if (redirectUrl) {
        navigate("/" + redirectUrl);
      } else navigate("/");
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

const signInWithEmail = async (
  navigate: any,
  setUser: any,
  fleetwireToken: string,
  email: string,
  password: string,
  redirectUrl: string | null,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.trim() || !emailRegex.test(email)) {
    toast.error("Please enter a valid email address");
    setLoading(false);
    return;
  }
  // Password length validation
  if (password.length < 8) {
    toast.error("Password must be at least 8 characters long");
    setLoading(false);
    return;
  }
  signInWithEmailAndPassword(auth, email, password)
    .then(async (result) => {
      const tokenDocRef = await getDoc(
        doc(firestore, "Tokens", "FleetwireAdmin")
      );

      const userDocRef = doc(firestore, "users", result.user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        const fleetwireCustomer = (await createFleetwireCustomer(
          result.user.displayName ?? result.user.email!,
          result.user.email!,
          tokenDocRef.data()?.key
        )) as FleetwireCustomer;

        saveUserDetails(result.user.uid, {
          uid: result.user.uid,
          email: result.user.email,
          phone: null,
          displayName: result.user.displayName ?? result.user.email,
          renterId: fleetwireCustomer.renter.r_id,
          photoURL: result.user.photoURL,
        });
        setUser({
          uid: result.user.uid,
          email: result.user.email,
          phone: null,
          displayName: result.user.displayName ?? result.user.email,
          renterId: fleetwireCustomer.renter.r_id,
          photoURL: result.user.photoURL,
        });
      } else {
        const userDetails = userDoc.data() as UserDocModel;
        setUser(userDetails);
      }
      if (redirectUrl) {
        navigate("/" + redirectUrl);
        setLoading(false);
        toast.success("Signin Successful");
      } else {
        toast.success("Signin Successful");
        navigate("/");
        setLoading(false);
      }
    })
    .catch((error) => {
      setLoading(false);
      console.log(error);
      toast.error("The username or Password you entered is incorrect");
    });
};

const signUpWithEmailAndPassword = async (
  navigate: any,
  setUser: any,
  fleetwireToken: string,
  email: string,
  password: string,
  redirectUrl: string | null,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.trim() || !emailRegex.test(email)) {
    toast.error("Please enter a valid email address");
    setLoading(false);
    return;
  }

  // Password length validation
  if (password.length < 8) {
    toast.error("Password must be at least 8 characters long");
    setLoading(false);
    return;
  }
  createUserWithEmailAndPassword(auth, email, password)
    .then(async (result) => {
      const tokenDocRef = await getDoc(
        doc(firestore, "Tokens", "FleetwireAdmin")
      );

      const userDocRef = doc(firestore, "users", result.user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        const fleetwireCustomer = (await createFleetwireCustomer(
          result.user.displayName ?? result.user.email!,
          result.user.email!,
          tokenDocRef.data()?.key
        )) as FleetwireCustomer;

        saveUserDetails(result.user.uid, {
          uid: result.user.uid,
          email: result.user.email,
          phone: null,
          displayName: result.user.displayName ?? result.user.email,
          renterId: fleetwireCustomer.renter.r_id,
          photoURL: result.user.photoURL,
        });
        setUser({
          uid: result.user.uid,
          email: result.user.email,
          phone: null,
          displayName: result.user.displayName ?? result.user.email,
          renterId: fleetwireCustomer.renter.r_id,
          photoURL: result.user.photoURL,
        });
      } else {
        const userDetails = userDoc.data() as UserDocModel;
        setUser(userDetails);
      }
      if (redirectUrl) {
        toast.success("Signin Successful");
        navigate("/" + redirectUrl);
        setLoading(false);
      } else {
        toast.success("Signin Successful");
        navigate("/");
        setLoading(false);
      }
    })
    .catch((error) => {
      if (error.code === "auth/email-already-in-use") {
        toast.error("User already exists");
        setLoading(false);
      } else {
        toast.error("The username or Password you entered is incorrect");
        setLoading(false);
      }
    });
};

const signOutFromGoogle = (navigate: any) => {
  signOut(auth)
    .then(() => {
      console.log("User signed out");
    })
    .catch((error) => {
      console.error(error);
    });
};

export const saveUserDetails = async (userId: string, userDetails: any) => {
  try {
    await setDoc(doc(firestore, "users", userId), userDetails);
    console.log("User details saved successfully.");
    toast.success("User details saved successfully.");
  } catch (error) {
    console.error("Error saving user details: ", error);
  }
};

const editUserDetails = async (userId: string, updatedDetails: any) => {
  try {
    const userDocRef = doc(firestore, "users", userId);
    await updateDoc(userDocRef, updatedDetails);
    console.log("User details updated successfully.");
  } catch (error) {
    console.error("Error updating user details: ", error);
  }
};

const readUserDetails = async (userId: string) => {
  try {
    const userDocRef = doc(firestore, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      console.log("User details:", userDoc.data());
      return userDoc.data() as UserDocModel;
    } else {
      console.log("No such user!");
      return null;
    }
  } catch (error) {
    console.error("Error reading user details: ", error);
    return null;
  }
};

export {
  firestore,
  auth,
  signInWithGoogle,
  signOutFromGoogle,
  signUpWithEmailAndPassword,
  signInWithEmail,
  storage,
  firebaseConfig,
};
