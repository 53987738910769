import React, { useState } from "react";
import Minus from "images/Minus.svg";
import Plus from "images/Plus.svg";
import NcImage from "shared/NcImage/NcImage";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="border-b border-gray-200">
      <button
        onClick={onToggle}
        className="flex justify-between w-full px-4 py-6 text-left items-center"
      >
        <span className="text-lg font-medium leading-7 text-left">{title}</span>
        <span>{isOpen ? <NcImage src={Minus} /> : <NcImage src={Plus} />}</span>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};

const RentalPolicies: React.FC = () => {
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };
  return (
    <>
      <div className="flex justify-center mb-16">
        <span className="md:text-4xl text-xl font-semibold leading-10 text-center tracking-tight">
          Rental Policies and Requirements
        </span>
      </div>
      <div className="max-w-3xl mx-auto mt-4">
        <Section
          title="How To Rent a Car at ERM Car Rental"
          isOpen={openSectionIndex === 0}
          onToggle={() => handleToggle(0)}
        >
          <ol className="list-decimal ml-5 text-base font-normal leading-6 text-left text-[#6B7280]">
            <li className="mb-2">
              <span>Book Here or Call:</span>
              <ul className="list-disc ml-5 ">
                <li>
                  Visit our website to browse our fleet and make a reservation.
                </li>
                <li>
                  Alternatively, give us a call to speak with our friendly staff
                  and choose the best vehicle for your trip.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              <span>Confirmation and Rental Agreement:</span>
              <ul className="list-disc ml-5">
                <li>
                  Once booked, you will receive a confirmation, and the rental
                  agreement will be sent electronically the same day as your
                  reservation.
                </li>
                <li>
                  The rental agreement can be conveniently signed
                  electronically.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              <span>Key Pickup:</span>
              <ul className="list-disc ml-5">
                <li>Upon arrival, retrieve the key from our secure lockbox.</li>
                <li>Hit the road and enjoy your Provo adventure.</li>
              </ul>
            </li>
          </ol>
        </Section>
        <Section
          title="Refund/Cancellation Policy"
          isOpen={openSectionIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          <ul className="list-disc ml-5 text-base font-normal leading-6 text-left text-[#6B7280]">
            <li className="mb-2">
              <span>Payment:</span>
              <ul className="list-disc ml-5">
                <li>
                  100% payment is due at the time of reservation to secure the
                  date(s).
                </li>
              </ul>
            </li>
            <li className="mb-2">
              <span>Cancellation/Rescheduling:</span>
              <ul className="list-disc ml-5">
                <li>
                  No penalty for cancellation or rescheduling up to 72 hours
                  before the rental date.
                </li>
                <li>
                  A 50% penalty of the total rental cost applies if the rental
                  is canceled less than 72 hours before the rental.
                </li>
                <li>
                  A cancellation fee of 100% of the total rental cost is charged
                  if canceled within 24 hours of the rental or for a no-show.
                </li>
                <li>No penalty for rescheduling.</li>
              </ul>
            </li>
            <li className="mb-2">
              <span>Vehicle Changes:</span>
              <ul className="list-disc ml-5">
                <li>
                  In the event of a mechanical breakdown, vehicles are subject
                  to change.
                </li>
                <li>
                  If dissatisfied with an alternative, receive a refund of 100%
                  of the total rental cost or reschedule without penalty.
                </li>
              </ul>
            </li>
          </ul>
        </Section>
        <Section
          title="Our Fleet Selection"
          isOpen={openSectionIndex === 2}
          onToggle={() => handleToggle(2)}
        >
          <ul className="list-disc text-base font-normal leading-6 text-left text-[#6B7280]">
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Minivan:</span>
                <ul className="list-disc ml-5">
                  <li>Family-Friendly</li>
                  <li>Spacious and Versatile</li>
                </ul>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Sedan:</span>
                <ul className="list-disc ml-5">
                  <li>Efficient for City Exploration</li>
                  <li>Comfortable and Stylish</li>
                </ul>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>SUV:</span>
                <ul className="list-disc ml-5">
                  <li>Versatile for Outdoor Adventures</li>
                  <li>Spacious for Family Trips</li>
                </ul>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Truck:</span>
                <ul className="list-disc ml-5">
                  <li>Powerful and Utilitarian</li>
                  <li>Durability for Rugged Terrain</li>
                </ul>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Sports Car:</span>
                <ul className="list-disc ml-5">
                  <li>Thrilling Drives</li>
                  <li>Speed and Sophistication</li>
                </ul>
              </li>
            </ul>
          </ul>
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            ERM Car Rental provides a diverse fleet catering to various
            preferences, ensuring an enjoyable travel experience for every
            customer in Orem, Utah.
          </span>
        </Section>
        <Section
          title="Insurance"
          isOpen={openSectionIndex === 3}
          onToggle={() => handleToggle(3)}
        >
          <ul className="list-disc text-base font-normal leading-6 text-left text-[#6B7280]">
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  Customers must have their own full-coverage insurance
                  (liability, comprehensive, collision, and loss of use).
                </span>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  Provide a declaration page of your insurance coverage via
                  email.
                </span>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  Our team will verify with your insurance company if the
                  coverage applies to the rented vehicle.
                </span>
              </li>
            </ul>
          </ul>
        </Section>
        <Section
          title="Security Deposit"
          isOpen={openSectionIndex === 4}
          onToggle={() => handleToggle(4)}
        >
          <ul className="list-disc text-base font-normal leading-6 text-left text-[#6B7280]">
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  A security deposit, ranging from $200 to $1,500 depending on
                  the chosen vehicle, will be charged at the time of booking.
                </span>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  The deposit is refundable upon the return of the vehicle in
                  the same condition.
                </span>
              </li>
            </ul>
          </ul>
        </Section>
        <Section
          title="Minimum Ages | Young Driver Fees"
          isOpen={openSectionIndex === 5}
          onToggle={() => handleToggle(5)}
        >
          <ul className="list-disc text-base font-normal leading-6 text-left text-[#6B7280]">
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  You must be at least 18 years old with a valid driver’s
                  license and a clean driving record.
                </span>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  Drivers between 18 – 24 will incur a young driver’s fee.
                </span>
              </li>
            </ul>
          </ul>
        </Section>
        <Section
          title="Vehicle Fueling Policy"
          isOpen={openSectionIndex === 6}
          onToggle={() => handleToggle(6)}
        >
          <ul className="list-disc text-base font-normal leading-6 text-left text-[#6B7280]">
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  At ERM Car Rental, we value transparency and aim to make your
                  car rental experience seamless. To ensure a smooth return
                  process for both you and subsequent renters, we have
                  established a straightforward fueling policy.
                </span>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Expectation:</span>
              </li>
              <ul className="list-disc ml-5">
                <li className="mb-2">
                  <span>
                    We kindly request that each vehicle be returned with a full
                    tank of fuel. This helps us maintain efficiency in our
                    operations and allows the next renter to start their journey
                    without delays.
                  </span>
                </li>
              </ul>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Penalties for Unfilled Tanks:</span>
              </li>
              <ul className="list-disc ml-5">
                <li className="mb-2">
                  <span>
                    If a vehicle is not returned with a full tank, a $10
                    refueling fee will be applied.
                  </span>
                </li>
              </ul>
              <ul className="list-disc ml-5">
                <li className="mb-2">
                  <span>
                    Additionally, there will be a charge of $5 per gallon for
                    the amount of fuel needed to reach a full tank.
                  </span>
                </li>
              </ul>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>How to Avoid Charges:</span>
              </li>
              <ul className="list-disc ml-5">
                <li className="mb-2">
                  <span>
                    Plan your trip to include time for refueling before
                    returning the vehicle.
                  </span>
                </li>
              </ul>
              <ul className="list-disc ml-5">
                <li className="mb-2">
                  <span>
                    Utilize nearby gas stations to fill up the tank on your way
                    back to our rental location.
                  </span>
                </li>
              </ul>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>
                  By adhering to our fueling policy, you contribute to a
                  positive rental experience for both yourself and future
                  customers. We appreciate your cooperation in maintaining our
                  fleet’s efficiency and ensuring that each ERM Car Rental
                  vehicle is ready for the next exciting journey.
                </span>
              </li>
            </ul>
          </ul>
        </Section>
        <Section
          title="Smoking and Pet Policies"
          isOpen={openSectionIndex === 7}
          onToggle={() => handleToggle(7)}
        >
          <ul className="text-base font-normal leading-6 text-left text-[#6B7280] mb-2">
            – Maintaining a fresh and clean environment for all our customers is
            a priority at ERM Car Rental. To ensure a pleasant experience for
            everyone, we strictly enforce a no-smoking policy within our rental
            vehicles. This policy contributes to creating a comfortable and
            healthy atmosphere for all passengers.
          </ul>
          <br />
          <ul className="text-base font-normal leading-6 text-left text-[#6B7280] mb-2">
            – For the convenience of those traveling with furry companions, ERM
            Car Rental offers pet-friendly options. We understand that pets are
            an integral part of your journey, and we strive to accommodate
            various travel preferences.
          </ul>
          <br />
          <ul className="mb-2 text-base font-normal leading-6 text-left text-[#6B7280] ">
            – Please note that ERM Car Rental imposes a $300 fee for smoking in
            our vehicles. This fee is intended to cover the extensive cleaning
            and deodorizing process required to restore the vehicle to its
            optimal condition. We appreciate your cooperation in adhering to our
            no-smoking policy and ensuring a clean and enjoyable experience for
            all passengers. Additionally, inquire about our pet-friendly
            rentals, and review associated fees and cleaning charges for any
            smoking or pet-related incidents. ERM Car Rental remains committed
            to upholding high standards of cleanliness and customer
            satisfaction.
          </ul>
        </Section>
        <Section
          title="Child Safety Guidelines"
          isOpen={openSectionIndex === 8}
          onToggle={() => handleToggle(8)}
        >
          <ul className="text-base font-normal leading-6 text-left text-[#6B7280] mb-2">
            At ERM Car Rental, the safety and well-being of our youngest
            passengers are of utmost importance. We understand the significance
            of complying with Utah County’s child safety seat regulations to
            ensure a secure travel experience for families. Our commitment to
            child safety extends to offering convenient solutions for families
            traveling with children. In accordance with local regulations, ERM
            Car Rental provides the option to rent child safety seats. This
            service is designed to ease the travel planning process for parents
            and guardians, allowing them to prioritize the safety and comfort of
            their little ones. We encourage all customers with young passengers
            to review our comprehensive policy on providing and renting child
            safety seats. By choosing ERM Car Rental, you’re not only selecting
            a reliable car rental service but also prioritizing the safety of
            your family.
          </ul>
        </Section>
        <Section
          title="Utah County Emergency Information"
          isOpen={openSectionIndex === 9}
          onToggle={() => handleToggle(9)}
        >
          <ul className="text-base font-normal leading-6 text-left text-[#6B7280] mb-2">
            Your safety is our top priority at ERM Car Rental. To ensure a
            secure travel experience in Utah County, familiarize yourself with
            essential emergency contact information. Be aware of local services
            for medical emergencies, law enforcement, and other critical
            situations. Understanding the local emergency numbers and services
            available provides you with valuable resources in the event of
            unforeseen circumstances. ERM Car Rental encourages all customers to
            prioritize safety by staying informed and prepared during their time
            in Utah County. Trust us to provide you with the necessary
            information to navigate emergencies with confidence.
          </ul>
        </Section>
        <Section
          title="Seasonal Travel Tips"
          isOpen={openSectionIndex === 10}
          onToggle={() => handleToggle(10)}
        >
          <ul className="list-disc text-base font-normal leading-6 text-left text-[#6B7280]">
            <ul className="list-disc ml-5">
              <li className="mb-2">
                Embarking on a journey through Utah County offers unique
                experiences throughout the seasons. At ERM Car Rental, we want
                to ensure that your travel plans align with the distinct
                characteristics of each season in this beautiful region. Whether
                you’re navigating winter wonderlands or basking in the warmth of
                summer, our seasonal travel tips provide valuable insights to
                enhance your trip.
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Winter Travel:</span>
                <ul className="list-disc ml-5">
                  <li>
                    Be prepared for snowy conditions, especially in higher
                    elevations.
                  </li>
                  <li>
                    Consider renting a four-wheel-drive vehicle for added
                    traction.
                  </li>
                  <li>
                    Check weather forecasts and road conditions before setting
                    out.
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Spring and Fall Travel:</span>
                <ul className="list-disc ml-5">
                  <li>Enjoy milder temperatures and vibrant landscapes.</li>
                  <li>Pack layers to accommodate temperature fluctuations.</li>
                  <li>
                    Explore local events and festivals during these transitional
                    seasons.
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                <span>Summer Travel:</span>
                <ul className="list-disc ml-5">
                  <li>Embrace the warm weather with outdoor activities.</li>
                  <li>
                    Stay hydrated, especially if engaging in outdoor adventures.
                  </li>
                  <li>
                    Plan visits to popular attractions during weekdays to avoid
                    crowds.
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                By tailoring your travel plans to the specific characteristics
                of each season, you can make the most of your Utah County
                adventure. ERM Car Rental is here to support you with helpful
                tips and reliable rental options designed for all seasonal
                conditions.
              </li>
            </ul>
          </ul>
        </Section>
      </div>
    </>
  );
};

export default RentalPolicies;
