"use client";

import { FC } from "react";
import LocationInput from "../LocationInput";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
import RentalCarTimesRangeInput from "./RentalCarTimesRangeInput";
import { useApp } from "data/app-provider";
import DropOffLocationInput from "../DropOffLocationInput";

export interface RentalCarSearchFormProps {}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = () => {
  const {
    dropOffLocationType,
    setDropOffLocationType,
    setDropOffLocation,
    pickupLocation,
    dropOffLocationExtras,
    setSelectedDropOffLocationExtra,
    setSearchedDropoffLocation,
  } = useApp();

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropOffLocationType === "same"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => {
            setDropOffLocationType("same");
            setDropOffLocation(pickupLocation);
            setSearchedDropoffLocation(pickupLocation?.name ?? "");
            dropOffLocationExtras?.options.map((location) => {
              if (location.title == pickupLocation?.name) {
                setSelectedDropOffLocationExtra(location);
              }
            });
          }}
        >
          Same drop off
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropOffLocationType === "different"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setDropOffLocationType("different")}
        >
          Different drop off
        </div>
      </div>
    );
  };

  const isDdropOffdifferent = dropOffLocationType === "different";

  return (
    <form className="w-full relative mt-8 rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      {/* {renderRadioBtn()} */}
      <div className={`relative flex flex-row`}>
        <LocationInput
          placeHolder="City or Airport"
          desc="Where are you going?"
          className="flex-1"
          sameDropOffLocation={dropOffLocationType === "same"}
        />
        {isDdropOffdifferent && (
          <>
            <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
            <DropOffLocationInput
              placeHolder="City or Airport"
              desc="Drop off location"
              className="flex-1"
              divHideVerticalLineClass="-inset-x-0.5"
            />
          </>
        )}
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <RentalCarDatesRangeInput className="flex-1" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <RentalCarTimesRangeInput
          dropOffLocationType={dropOffLocationType}
          className="flex-2"
        />
      </div>
    </form>
  );
};

export default RentalCarSearchForm;
