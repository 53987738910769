import React, { FC } from "react";
import rentalStep1 from "images/RentalStep1.png";
import rentalStep2 from "images/RentalStep2.png";
import rentalStep3 from "images/RentalStep3.png";
import rentalStep4 from "images/RentalStep4.png";
import NcImage from "shared/NcImage/NcImage";
import VectorImg from "images/VectorHIW.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";

export interface RentalStepProp {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: RentalStepProp["data"] = [
  {
    id: 1,
    img: rentalStep1,
    title: "Step 1 : Visit the Booking Page",
    desc: "Start by navigating to the ERM Car Rental booking page. Here, you’ll select your rental dates. Make sure to check the availability for the days you need the vehicle.",
  },
  {
    id: 2,
    img: rentalStep2,
    title: "Step 2 : Choose Your Pick-Up and Drop-Off Locations",
    desc: "After setting your dates, choose where you would like to pick up and drop off the vehicle. ERM offers flexible locations to make your rental experience convenient.",
  },
  {
    id: 3,
    img: rentalStep3,
    title: "Step 3 : Payment and Insurance Verification",
    desc: "Proceed to payment. During this step, you’ll also need to verify your insurance details to ensure you’re covered during the rental period. Make sure all information is accurate to avoid any delays.",
  },
  {
    id: 4,
    img: rentalStep4,
    title: "Step 4 : Pick Up Your Rental",
    desc: "On the day of your trip, you will receive a text message with the exact location of your rental car. This message will include a photo of the car for easy identification. Simply head to the specified location to pick up your vehicle and start your journey!",
  },
];

const RentalSteps: FC<RentalStepProp> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-center md:leading-9 leading-5 text-center ">
        <h2 className="md:mb-8 mb-0 md:text-6xl text-2xl font-bold md:max-w-[600px] max-w-[300px] text-[#3B3B3B]">
          How to Rent a Car at ERM Car Rental
        </h2>
      </div>
      <div className="md:mt-20 mt-8 relative grid lg:grid-cols-4 gap-20">
        <img
          className="hidden lg:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto mb-8"
          >
            <NcImage
              containerClassName="mb-8 mx-auto"
              src={item.img}
            />

            <div className="text-center">
              <h3 className="text-lg font-bold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400 font-medium">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-16">
        <Link to={"/listing-car"}>
          <button className=" text-white font-bold py-2 px-4 rounded-xl disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 h-auto inline-flex items-center justify-center transition-colors">
            Browse All Vehicles
          </button>
        </Link>
      </div>
    </div>
  );
};

export default RentalSteps;
