import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/Gotoplaces1.png";
import HIW2img from "images/Gotoplaces2.png";
import HIW3img from "images/Gotoplaces3.png";
import VectorImg from "images/VectorHIW.svg";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Book & relax",
    desc: "Easy, Contactless Check in and Check out process",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Smart checklist",
    desc: "#1 Car Rental In Utah",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Save more",
    desc: "No Hidden Fees",
  },
];

const GoPlacesWith: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <div className="flex flex-col mb-8 relative">
        <span className="md:text-4xl text-xl font-semibold leading-6 md:leading-10 text-center">
          Go Places With Us!
        </span>
      </div>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20 md:mx-44 mx-4">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-8 max-w-[200px] mx-auto"
              src={item.img}
            />

            <div className="text-center mt-auto">
              <span className="text-xl font-semibold md:leading-7 leading-5 text-center">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoPlacesWith;
