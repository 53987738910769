import { useApp } from "data/app-provider";
import React from "react";

function Cancellelation() {
  const { companyData } = useApp();
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: companyData?.company?.meta?.cancellation ?? "",
      }}
    />
  );;
}

export default Cancellelation;
