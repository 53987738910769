import React from "react";
import NcImage from "shared/NcImage/NcImage";
import RentalHoursImg from "images/RentalHours.png";

const CarRentalHours: React.FC = () => {
  const hours = [
    { day: "Monday", time: "8:00 AM to 11:30 PM" },
    { day: "Tuesday", time: "8:00 AM to 11:30 PM" },
    { day: "Wednesday", time: "8:00 AM to 11:30 PM" },
    { day: "Thursday", time: "8:00 AM to 11:30 PM" },
    { day: "Friday", time: "8:00 AM to 11:30 PM" },
    { day: "Saturday", time: "8:00 AM to 11:30 PM" },
    { day: "Sunday", time: "8:00 AM to 11:30 PM" },
  ];

  return (
    <section className="flex items-center justify-center">
      <div className="flex flex-col md:flex-row items-center justify-between w-full max-w-6xl">
        <div className="flex-1 md:m-8 sm:m-0 text-left">
          <h1 className="md:text-4xl text-xl font-semibold md:leading-10 leading-6 mb-4">
            ERM Car Rental Hours
          </h1>
          <p className="text-base font-medium leading-6 md:mb-4 mb-8">
            Business Hours: 24/7 Pickup and Drop off
          </p>
          <div className="space-y-2">
            {hours.map((hour, index) => (
              <div key={index} className="flex items-center justify-between">
                <span className="text-[#166534] bg-[#DCFCE7] px-4 py-2 rounded-full font-medium text-xs">{hour.day}</span>
                <span className="font-semibold">{hour.time}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="hidden lg:block flex-1 mt-8 md:mt-0 text-center">
          <img className="mb-8 mx-auto" src={RentalHoursImg} style={{height:"450px"}}/>
        </div>
      </div>
    </section>
  );
};

export default CarRentalHours;
