import React, { useEffect } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleLocationClick = (event: any) => {
    event.preventDefault(); // Prevent default anchor tag behavior
    navigate("/#section"); // Navigate programmatically to '/#section'
  };

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative ">
      {NAVIGATION_DEMO.reduce<React.ReactNode[]>((acc, item, index) => {
        acc.push(<NavigationItem key={item.id} menuItem={item} />);
        if (index === 0) {
          acc.push(
            <a
              href="#section"
              onClick={handleLocationClick}
              className="inline-flex items-center text-sm xl:text-base font-normal text-primary-200 py-2 px-4 xl:px-5 rounded-full hover:text-primary-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"
              key="location-link"
            >
              Locations
            </a>
          );
        }
        return acc;
      }, [])}
    </ul>
  );
}

export default Navigation;
