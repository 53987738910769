import rightImg from "images/image.jpeg";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionFounder from "containers/PageAbout/SectionFounder";
import DownloadLink from "./RentalAgreement";
import { globalJson } from "global/global_json";
import SectionGridCategoryBox from "./SectionGridLocation";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";

function PageHome3() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <DownloadLink /> */}

      {/* SECTION HERO */}
      {/* <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 "> */}
      <SectionHero3 />
      {/* </div> */}
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
        <div id="section">
          <SectionGridCategoryBox />
        </div>
        <div className="mt-24">
          <SectionHowItWork />
        </div>
        <div className="mt-36">
          <SectionOurFeatures />
        </div>
      </div>
      <div className="container relative" style={{ padding: "0px" }}>
        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />

        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
          <SectionHero
            rightImg={rightImg}
            heading="👋 About Us."
            btnText=""
            subHeading={globalJson?.about_us_para}
          />

          {/* <SectionFounder /> */}
        </div>
      </div>
      <div className="relative py-16">
        <BackgroundSection />
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
      {/* <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionSubscribe2 />
      </div> */}
    </div>
  );
}

export default PageHome3;
