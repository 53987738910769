import React, { useEffect, useState } from "react";
import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { TaxonomyType } from "data/types";
import { useApp } from "data/app-provider";

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categoryCardType = "card1",
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { companyData } = useApp();
  const categories = companyData?.company?.delivery_locations

  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;
    default:
      CardComponentName = CardCategoryBox1;
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const visibleCategories =
    showAllCategories || isMobile ? categories : categories?.slice(0, 8);
  const showViewAllButton = categories?.length && categories.length > 8;
  const toggleShowAll = () => {
    setShowAllCategories(!showAllCategories);
  };

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <div className="flex mb-8 relative justify-between">
        <span className="lg:text-6xl md:text-3xl text-3xl font-semibold lg:leading-[73.14px] leading-3 text-left text-[#3B3B3B]">
          Our Locations
        </span>
        {showViewAllButton && (
          <span className="hidden sm:block flex-shrink-0">
            <ButtonSecondary
              className="!leading-5 text-lg font-medium text-[#374151] rounded-full"
              onClick={toggleShowAll}
            >
              <span className="mr-3">View All</span>
              {showAllCategories ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 15L12 9L6 15"
                    stroke="#374151"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="#374151"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </ButtonSecondary>
          </span>
        )}
      </div>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {visibleCategories?.map((item, i) => (
          <CardComponentName location={item} />
        ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
