import React, { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab?: (item: string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "🎈 Latest Articles",
  onClickTab,
}) => {
  const navigate = useNavigate();
  const [tabActiveState, setTabActiveState] = useState(tabActive);
  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  return (
    <div className="flex flex-col mb-8 relative">
      <div className="flex items-center justify-between">
        <span className="lg:text-6xl md:text-3xl text-3xl font-semibold lg:leading-[73.14px] leading-3 text-left text-[#3B3B3B]">
          {heading}
        </span>

        <span className="hidden sm:block flex-shrink-0">
          <ButtonSecondary
            className="!leading-5 text-lg font-medium text-[#374151] rounded-full"
            onClick={() => navigate("listing-car")}
          >
            <span className="mr-3">View All</span>
            <svg
              width="23"
              height="16"
              viewBox="0 0 23 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.51562 7C0.96334 7 0.515625 7.44772 0.515625 8C0.515625 8.55228 0.96334 9 1.51562 9V7ZM22.3956 8.70711C22.7862 8.31658 22.7862 7.68342 22.3956 7.29289L16.0317 0.928932C15.6412 0.538408 15.008 0.538408 14.6175 0.928932C14.2269 1.31946 14.2269 1.95262 14.6175 2.34315L20.2743 8L14.6175 13.6569C14.2269 14.0474 14.2269 14.6805 14.6175 15.0711C15.008 15.4616 15.6412 15.4616 16.0317 15.0711L22.3956 8.70711ZM1.51562 9H21.6885V7H1.51562V9Z"
                fill="#374151"
              />
            </svg>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;
