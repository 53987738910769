import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";

export interface ListingCarPageProps {
  className?: string;
}

const AvailableCarsPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <Helmet>
        <title>UTAH || Car rental</title>
      </Helmet>
      {/* <BgGlassmorphism /> */}

      <div className="container relative">
        <SectionGridFilterCard className="mt-12 pb-24 lg:pb-28" />
      </div>
    </div>
  );
};

export default AvailableCarsPage;
