import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import CarRental from "components/carRental/CarRental";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import CarRentalHours from "components/RentalHours/RentalHours";
import RentalPolicies from "components/RentalPolicies/RentalPolicies";
import RentalSteps from "components/RentalSteps/RentalSteps";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import React from "react";

function Requirements() {
  return (
    <div>
      <div className="py-16">
        <RentalSteps />
      </div>
      <div className="py-16">
        <CarRentalHours />
      </div>
      <div className="py-16">
        <RentalPolicies />
      </div>
      <div className="py-16">
        <GoPlacesWith />
      </div>
      <div className="py-16">
        <CarRental />
      </div>
      <div className="relative py-16">
        <BackgroundSection />
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
    </div>
  );
}

export default Requirements;
