import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { useApp } from "data/app-provider";
import { Insurance, StripeVerification } from "models/FleetwireCustomer";
import { BonzahProduct } from "models/BonzahProducts";
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    flexDirection: "column",
    display: "flex",
  },
  heading1: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 22,
    color: "#000000",
  },
  heading2: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 22,
    color: "#FF0000",
  },
  heading3: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 14,
    color: "#000000",
  },
  text: {
    //    fontFamily: "Arial",
    fontSize: 14,
    color: "#000000",
  },
  textBold: {
    //    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 14,
    color: "#000000",
  },
  section: {
    marginBottom: 10,
    marginLeft: 10,
  },
  sectionPoint: {
    marginBottom: 10,
    marginLeft: 40,
  },
  space: {
    marginBottom: 40,
  },
});

const signatureStylesText = [
  "Playball",
  "Qwitcher Grypen",
  "Montez",
  "My Soul",
  "Ms Madi",
  "Niconne",
];

// // Register fonts with direct URLs
Font.register({
  family: "Playball",
  src: "/fonts/Playball-Regular.ttf",
});
Font.register({
  family: "Qwitcher Grypen",
  src: "/fonts/QwitcherGrypen-Regular.ttf",
});
Font.register({
  family: "Montez",
  src: "/fonts/Montez-Regular.ttf",
});
Font.register({
  family: "My Soul",
  src: "/fonts/MySoul-Regular.ttf",
});
Font.register({
  family: "Ms Madi",
  src: "/fonts/MsMadi-Regular.ttf",
});
Font.register({
  family: "Niconne",
  src: "/fonts/Niconne-Regular.ttf",
});

// Create Document Component

export interface RentalAgreemaentProps {
  selectedSignatureIndex: number;
  stripeVerification: StripeVerification;
  selectedBonzahProduct: BonzahProduct[];
  axleInsurance: Insurance | null;
  bonzahCertificate?: string;
}
const RentalAgreement: React.FC<RentalAgreemaentProps> = ({
  selectedSignatureIndex,
  stripeVerification,
  selectedBonzahProduct,
  axleInsurance,
  bonzahCertificate,
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading3}>Elk Ridge Management</Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            1. Introduction Welcome to Elk Ridge Management. By using our
            services, you agree to comply with and be bound by the following
            terms and conditions. Please read them carefully.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            2. Rental Agreement 2.1. The rental agreement is between Elk Ridge
            Management (the "Company") and the person renting the vehicle (the
            "Renter"). 2.2. The rental period, vehicle details, and charges will
            be outlined in the rental agreement signed by the Renter.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            3. Eligibility 3.1. Renters must be at least 21 years old. 3.2.
            Renters must hold a valid driver’s license for at least one year
            prior to the rental date. 3.3. Additional drivers must meet the same
            eligibility criteria and be listed on the rental agreement.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            4. Vehicle Use 4.1. Vehicles must be used in a lawful and safe
            manner. 4.2. Renters are prohibited from using vehicles for illegal
            activities, off-road driving, or towing. 4.3. Smoking and
            transporting pets in the vehicle are prohibited unless prior
            permission is obtained.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            5. Insurance and Liability 5.1. The Company provides basic insurance
            coverage. Renters are responsible for any damage not covered by the
            insurance. 5.2. Renters may opt for additional insurance coverage at
            an extra cost. 5.3. Renters are liable for any fines, penalties, or
            damages incurred during the rental period.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            6. Vehicle Condition and Maintenance 6.1. Vehicles are rented in
            good condition. Renters must return the vehicle in the same
            condition, except for normal wear and tear. 6.2. Renters are
            responsible for regular vehicle maintenance (e.g., checking oil and
            tire pressure) during long rentals.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            7. Fuel Policy 7.1. Vehicles are provided with a full tank of fuel.
            Renters must return the vehicle with a full tank. 7.2. If the
            vehicle is not returned with a full tank, refueling charges will
            apply.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            8. Rental Charges and Payment 8.1. Rental charges include the rental
            rate, insurance, and applicable taxes. 8.2. Additional charges may
            apply for extra services, additional drivers, or violations of the
            rental agreement. 8.3. Payment is due upon vehicle return. Accepted
            payment methods include credit cards and debit cards.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            9. Cancellations and Modifications 9.1. Cancellations made 48 hours
            before the rental start date are eligible for a full refund. 9.2.
            Cancellations made within 48 hours of the rental start date may
            incur a cancellation fee. 9.3. Modifications to the rental agreement
            (e.g., extending the rental period) must be requested in advance and
            are subject to availability.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            10. Emergency Assistance 10.1. In case of an accident or breakdown,
            Renters must contact the Company immediately. 10.2. The Company will
            provide roadside assistance as necessary.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            11. Termination 11.1. The Company reserves the right to terminate
            the rental agreement and repossess the vehicle if the Renter
            violates the terms and conditions. 11.2. No refunds will be issued
            for early termination due to violation of terms.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            12. Privacy and Data Protection 12.1. The Company collects and
            processes personal data in accordance with applicable data
            protection laws. 12.2. Personal data is used for the purpose of
            providing rental services and may be shared with third parties for
            operational purposes.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            13. Governing Law 13.1. These terms and conditions are governed by
            the laws of the state of Utah. 13.2. Any disputes arising from the
            rental agreement will be subject to the jurisdiction of Utah courts.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            14. Amendments 14.1. The Company reserves the right to amend these
            terms and conditions at any time. Amendments will be effective upon
            posting on the Company’s website.
          </Text>
        </View>
        <View style={styles.space}>
          <Text style={styles.text}>
            By renting a vehicle from Elk Ridge Management, you acknowledge that
            you have read, understood, and agreed to these terms and conditions.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Elk Ridge Management - Cancellation Policy
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            1. General Policy At Elk Ridge Management, we understand that plans
            can change. Our cancellation policy is designed to provide
            flexibility for our customers while ensuring that we can manage our
            fleet efficiently.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>2. Cancellation Process</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            2.1. To cancel a reservation, please contact our customer service
            team via email at vehicles@elkridgemgmt.com.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            2.2. Provide your reservation number and the name under which the
            reservation was made.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>3. Cancellation Fees</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            3.1. Cancellations Made 48 Hours or More Before Rental Start Date:
            You are eligible for a full refund of any prepaid amount.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            3.2. Cancellations Made Less Than 48 Hours Before Rental Start Date:
            A cancellation fee equivalent to one day's rental charge will apply.
            The remaining balance, if any, will be refunded.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            3.3. No-Show: If you fail to pick up the vehicle at the agreed time
            and location without prior cancellation, a no-show fee equivalent to
            one day's rental charge will apply. The remaining balance, if any,
            will be refunded.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>4. Refunds</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            4.1. Refunds will be processed within 7-10 business days after the
            cancellation request is confirmed.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            4.2. Refunds will be issued to the original payment method used
            during the booking process.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>5. Modifications to Reservations</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            5.1. If you need to modify your reservation (e.g., change the rental
            dates or vehicle type), please contact us as soon as possible.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            5.2. Modifications are subject to availability and may result in
            changes to the rental rate.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            5.3. If the modified reservation is canceled, the cancellation
            policy will apply based on the new rental start date.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>6. Special Circumstances</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            6.1. Force Majeure: In the event of unforeseen circumstances beyond
            your control (e.g., natural disasters, severe weather, or government
            restrictions), please contact us to discuss potential accommodations
            or waivers of cancellation fees.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            6.2. Medical Emergencies: If a medical emergency prevents you from
            commencing your rental, please provide documentation, and we will
            consider waiving cancellation fees on a case-by-case basis.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>7. Third-Party Bookings</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            7.1. If your reservation was made through a third-party booking
            platform, their cancellation policy may differ. Please refer to
            their terms and conditions for cancellations and refunds.
          </Text>
        </View>
        <View style={styles.sectionPoint}>
          <Text style={styles.text}>
            8. Contact Information For any questions or concerns regarding this
            cancellation policy, please contact our customer service team at
            vehicles@elkridgemgmt.com.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.heading1, ...styles.section }}>
            Primary Driver
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Name: {stripeVerification?.person_details?.first_name}{" "}
            {stripeVerification?.person_details?.last_name}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            DL#:{" "}
            {stripeVerification?.person_details.document_number === ""
              ? "N/A"
              : stripeVerification?.person_details.document_number}{" "}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Issued Date: {stripeVerification?.person_details.issued_date?.month}
            /{stripeVerification?.person_details.issued_date?.day}/
            {stripeVerification?.person_details.issued_date?.year}
          </Text>
        </View>
        {/* <View style={styles.section}>
          <Text style={styles.heading3}>
            DL Expiration:{" "}
            {stripeVerification?.person_details.expiration_date.day}/
            {stripeVerification?.person_details.expiration_date.month}/
            {stripeVerification?.person_details.expiration_date.year}
          </Text>
        </View> */}
        <View style={styles.section}>
          <Text style={styles.heading1}>Insurance</Text>
        </View>

        {selectedBonzahProduct != null ? (
          selectedBonzahProduct?.map((selectedBonzahOption) => (
            <View style={styles.section}>
              <Text style={styles.heading3}>
                Insurance Name : {selectedBonzahOption.name}
              </Text>
            </View>
          ))
        ) : (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance Policy # : {axleInsurance?.results["policyNumber"]}
            </Text>
          </View>
        )}
        {selectedBonzahProduct != null ? (
          <View style={styles.section}>
            <Text style={styles.heading3}>Insurance Provided by : Bonzah</Text>
          </View>
        ) : (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance Company : {axleInsurance?.results["carrier"]}
            </Text>
          </View>
        )}
        {selectedBonzahProduct != null && (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance agreement :{" "}
              {bonzahCertificate !== null
                ? bonzahCertificate
                : "Will be provided in the email"}
            </Text>
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.heading1}>Signature</Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              ...styles.heading1,
              ...{ fontFamily: signatureStylesText[selectedSignatureIndex] },
            }}
          >
            {stripeVerification?.person_details?.first_name}{" "}
            {stripeVerification?.person_details?.last_name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const DownloadLink: React.FC = () => {
  const {
    selectedBonzahProduct,
    stripeVerification,
    selectedSignatureIndex,
    axleInsurance,
  } = useApp();
  return (
    <div>
      <PDFDownloadLink
        document={
          <RentalAgreement
            selectedSignatureIndex={selectedSignatureIndex}
            stripeVerification={stripeVerification!}
            selectedBonzahProduct={selectedBonzahProduct}
            axleInsurance={axleInsurance}
          />
        }
        fileName="rental_agreement.pdf"
        className=" cursor-pointer"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Rental Agreement"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default RentalAgreement;
